<template>
  <div class="task_box" :style="{height: $store.state.menuFlag?'calc(100% - 10px)':'calc(100% - 90px)'}">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="任务监测" name="first">
        <div class="task-page">
          <div class="task-header">
            <span>项目:</span>
            <!--            <el-select v-model="ProjectId" placeholder="请选择">-->
            <!--              <el-option-->
            <!--                v-for="(item,index) in list"-->
            <!--                :key="index"-->
            <!--                :label="item.Name"-->
            <!--                :value="item.Id">-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <Cascader :value.sync="ProjectId" :options="list" :multiple="false"></Cascader>

            <span>任务名称:</span>
            <el-input v-model="Name" placeholder="请输入任务名称"></el-input>
            <span>创建人:</span>
            <el-input v-model="CreatedBy" placeholder="请输入随访创建人"></el-input>
            <el-date-picker
                v-model="time"
                type="daterange"
                range-separator="至"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <el-button icon="el-icon-search" @click="FollowUpTestPackage">查询</el-button>
          </div>
          <div class="task-content content">
            <div class="task-content-left">
              <ul style="overflow-y:scroll;overflow-x: hidden">
                <li v-for="(item,index) in testing" :key="index" @click="FollowUpMonitor(item,index)"
                    :class="{active:index==isactive}">
                  <div style="display: flex;justify-content: space-between;margin-bottom: 20px">
                    <span class="task-content-left-span">{{ item.Name }}</span>
                    <el-tag>{{ item.StatusName }}</el-tag>
                  </div>
                  <div class="task-bottom">
                    <img src="../../assets/image/icon/task-create-user.png" alt=""
                         style="width: 13px;height: 15px;vertical-align: -2px;margin-left: 3px">
                    <span>创建人:</span>
                    <span>{{ item.OwnerName }}</span>
                  </div>
                  <div class="task-bottom">
                    <img src="../../assets/image/taskmanagement/time.png" alt=""
                         style="width: 16px;height: 16px;vertical-align: -2px">
                    <span>创建时间:</span>
                    <span>{{ item.CreateTime }}</span>
                  </div>
                  <div style="display: flex;position: relative" class="task-bottom">
                    <img src="../../assets/image/icon/task-jindu.png" alt=""
                         style="width: 14px;height: 14px;position: absolute;top: 2px">
                    <span style="position: absolute;left: 17px">进度:</span>
                    <el-progress :text-inside="false" :stroke-width="8" :percentage="item.percentage"
                                 :format="format(item)" style="position: absolute;left: 50px"></el-progress>
                  </div>
                </li>
              </ul>
            </div>
            <div class="task-content-right">
              <div style="display: flex;margin-bottom: 20px;">
                <span class="task-content-left-span" style="margin-right: 20px">{{ detailed.Name }}</span>
                <el-tag>{{ detailed.StatusName }}</el-tag>
              </div>
              <div class="task-content-right-header">
                <img src="../../assets/image/icon/task-create-user.png" alt=""
                     style="width: 16px;height: 17px;vertical-align: -2px;margin-left: 3px">
                <span>创建人:</span>
                <span style="margin-right: 30px">{{ detailed.OwnerName }}</span>
                <img src="../../assets/image/taskmanagement/time.png" alt=""
                     style="width: 20px;height: 20px;vertical-align: -4px">
                <span>创建时间:</span>
                <span style="margin-right: 30px">{{ detailed.CreateTime }}</span>
                <img src="../../assets/image/icon/task-sum.png" alt=""
                     style="width: 18px;height: 18px;vertical-align: -4px">
                <span>总随访人数:</span>
                <span style="color: #319EFD;margin-right: 30px ">{{ detailed.TotalCount }}人</span>
                <span>成功:</span>
                <span style="color: #FF9900;margin-right: 30px">{{ detailed.SuccessCount }}人</span>
                <span>失败:</span>
                <span style="color: #FA5A8E;margin-right: 30px">{{ detailed.FailCount }}人</span>
                <span>成功率:</span>
                <span style="color: #00E2C4">{{ detailed.percentage }}%</span>
              </div>
              <div style="margin-top: 22px" class="task-content-query">
                <span>随访状态:</span>
                <el-select
                    style="margin-right: 20px"
                    v-model="Status"
                    placeholder="请选择状态"
                    size="mini"
                >
                  <el-option
                      :label="item.Name"
                      :value="item.Id"
                      v-for="(item, index) in FollowUpStatus"
                      :key="index"
                  ></el-option>
                </el-select>
                <span>关键字:</span>
                <el-input v-model="listQuery1.Key"></el-input>
                <el-button icon="el-icon-search">查询</el-button>
                <el-button icon="el-icon-refresh" class="el-button-plan">重置</el-button>
                <el-button style="float: right" icon="el-icon-upload2">数据导出</el-button>
              </div>
              <div style="margin-top: 15px" class="content">
                <el-table
                    ref="multipleTable"
                    :data="dataList"
                    tooltip-effect="dark"
                    style="width: 100%;margin-bottom: 10px;"
                    :header-cell-style="{ background: 'rgb(240, 247, 253)'}"
                    border>
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                  <el-table-column
                      v-if="admin=='00000000-0000-0000-0000-000000000000'"
                      prop="OrganizationName"
                      label="组织名称"
                      min-width="80"
                      align="center"
                  >
                  </el-table-column>
                  <el-table-column prop="TargetName" label="患者名称" align="center" width="90"></el-table-column>
                  <el-table-column label="联系方式" align="center" width="130">
                    <template slot-scope="scope">
                      <span>{{ scope.row.TargetTel | formPhone }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="PatientName" label="患者性别" align="center" width="90"></el-table-column>
                  <el-table-column prop="ChannelName" label="随访方式" width="120" align="center">
                  </el-table-column>
                  <el-table-column prop="BeginTime" label="随访开始时间" align="center"
                                   min-width="80"></el-table-column>
                  <el-table-column prop="EndTime" label="随访结束时间" align="center" min-width="80"></el-table-column>
                  <el-table-column prop="StatusName" label="随访状态" width="120" align="center">
                  </el-table-column>
                  <el-table-column prop="QuestionnaireName" label="问卷名称" min-width="80"
                                   align="center"></el-table-column>
                  <!-- <el-table-column label="操作"  width="80" align="center">
                    <template slot-scope="scope" >
                      <el-link :underline="false" @click="see(scope.row)"
                      >查看</el-link>
                    </template>
                  </el-table-column> -->
                </el-table>
                <div>
                  <Pagination
                      :total="total1"
                      :page.sync="listQuery1.page"
                      :limit.sync="listQuery1.rows"
                      @pagination="FollowUpMonitorlist(TaskID)"
                  >
                  </Pagination>
                </div>
              </div>


            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="规则监测" name="second">
        <div class="task_box1">
          <div>
            <div class="task_box2"
                 style="margin-bottom: 10px;border-radius: 10px;padding: 20px 20px 20px 20px;background: #FFFFFF;  box-sizing: border-box;">
              <span>项目:</span>
              <!--              <el-select v-model="RuleProjectId" placeholder="请选择" @change="RuleProjectChange">-->
              <!--                <el-option-->
              <!--                  v-for="(item,index) in ruleList"-->
              <!--                  :key="index"-->
              <!--                  :label="item.Name"-->
              <!--                  :value="item.Id">-->
              <!--                </el-option>-->
              <!--              </el-select>-->
              <Cascader :value.sync="RuleProjectId" :options="ruleList" :multiple="false"
                        @MyChange="RuleProjectChange"></Cascader>

              <span>问卷:</span>
              <el-select v-model="CRFId" placeholder="请选择">
                <el-option
                    v-for="(item,index) in CRFSurface"
                    :key="index"
                    :label="item.questionnaireName"
                    :value="item.Id">
                </el-option>
              </el-select>
              <span style="margin-right: 10px;">关键字:</span>
              <span style="display: inline-block;"><el-input v-model="listQuery.Key"
                                                             placeholder="请输入内容"></el-input></span>
              <el-button @click="query" type="primary" icon="el-icon-search" style=" margin-left: 10px;">搜索
              </el-button>
            </div>
            <div style="padding: 20px;background: #FFFFFF;  box-sizing: border-box;height: 690px;">
              <el-table
                  border
                  :data="tableData"
                  :header-cell-style="{ background: 'rgb(240, 247, 253)'}"
                  style="width: 100%;height: 600px;margin-bottom: 10px;">
                <el-table-column
                    v-if="admin=='00000000-0000-0000-0000-000000000000'"
                    prop="OrganizationName"
                    label="组织名称"
                    min-width="80"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="Code"
                    label="规则编号"
                    width="300">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="Name"
                    label="规则名称">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="ChannelName"
                    width="280"
                    label="随访方式">
                </el-table-column>
                <el-table-column
                    align="center"
                    width="180"
                    prop="StatusName"
                    label="随访状态">
                </el-table-column>
                <el-table-column
                    v-if="admin!='00000000-0000-0000-0000-000000000000'"
                    width="180"
                    align="center"
                    label="操作">
                  <template slot-scope="scope">
                    <el-link
                        v-show="scope.row.Status==2"
                        :underline="false"
                        @click="StartRule(scope.row)"
                    ><i class="el-icon-edit-outline"></i>启动
                    </el-link>
                    <el-link
                        v-show="scope.row.Status==0"
                        :underline="false"
                        @click="StopRule(scope.row)"
                    ><i class="el-icon-edit-outline"></i>停止
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
              <div>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.page"
                    :limit.sync="listQuery.rows"
                    @pagination="RuleMonitoring()"
                >
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import TaskDetails from "@/components/task/TaskDetails";
import FollowTask from "../../api/FollowTask"
import derive from "../../api/derive"
import Pagination from "../../components/Pagination/index.vue"
import ProjectManagement from "../../api/ProjectManagement"
import {local} from "../../utils/storage"
import Cascader from "@/components/Cascader.vue";

export default {
  name: "task",
  data() {
    return {
      admin: local.get("UserId"),
      isactive: 0,
      tableData: [],
      activeName: 'first',
      detailsDrawer: false, // 查看打开的抽屉
      multipleSelection: [], // 多选数据
      dataList: [],
      FollowUpStatus: [
        // {Id: '00',Name: "等待"},
        {Id: '01', Name: "删除"},
        {Id: '02', Name: "停止"},
        {Id: '03', Name: "终止"},
        {Id: '04', Name: "随访中"},
        {Id: '05', Name: "人工随访完成"},
        {Id: '06', Name: "短信随访完成"},
        {Id: '07', Name: "已接听"},
        {Id: '08', Name: "空号"},
        {Id: '09', Name: "占线"},
        {Id: '10', Name: "未接"},
        {Id: '11', Name: "无法接通"},
        {Id: '12', Name: "关机"},
        {Id: '13', Name: "停机"},
        {Id: '14', Name: "线路盲区"},
        {Id: '15', Name: "主叫欠费"},
        {Id: '16', Name: "无可用线路"},
        {Id: '17', Name: "其它"},
      ],
      Name: '',
      time: [],
      ProjectId: ["ALL"],
      list: [{Name: "全部", Id: "ALL", ParentId: null}],
      ruleList: [{Name: "全部", Id: "ALL", ParentId: null}],
      Status: '',
      Key: "",
      testing: [],
      detailed: {},
      RuleProjectId: ["ALL"],
      CRFSurface: [],
      CRFId: '',
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      total1: 0,
      listQuery1: {
        Key: "",
        page: 1,
        rows: 10,
      },
      CreatedBy: "",
      TaskID: '',
    }
  },
  components: {
    Cascader,
    Pagination
  },
  created() {
    this.ProjectList()
    this.$route.query.name && (this.activeName = this.$route.query.name)
  },
  beforeRouteUpdate(to, from, next) {
    to.query.name && (this.activeName = to.query.name)
    next()
  },
  methods: {
    //启动
    StartRule(row) {
      const parameter = {
        Id: row.Id
      }
      this.$confirm('此操作将启动规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.StartRuleData(parameter).then(res => {
          console.log(res);
          if (res.data.Status == 1) {
            row.Status = 0
            this.$message({
              type: 'success',
              message: '启动成功!'
            });
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    //停止
    StopRule(row) {
      const parameter = {
        Id: row.Id
      }
      this.$confirm('此操作将停止规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.StopRuleData(parameter).then(res => {
          if (res.data.Status == 1) {
            row.Status = 2
            this.$message({
              type: 'success',
              message: '停止成功!'
            });
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    handleClick(tab) {
      if (tab.index == '1') {
        this.RuleMonitoring()
      }
      console.log(tab);
    },
    query() {
      this.RuleMonitoring()
    },
    //问卷
    RuleProjectChange(e) {
      this.CRFId = ''
      const ParameterProject = {
        ProjectId: e.slice(-1)
      }
      derive.CRFSurfaceData(ParameterProject).then(res => {
        console.log(res);
        if (res.data.Status == 1) {
          this.CRFSurface = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    //规则监控列表
    RuleMonitoring() {
      const parameter = {
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        ProjectId: this.ProjectId,
        ProjectQuestionnaireId: this.CRFId,
      }
      FollowTask.RuleMonitoringData(parameter).then(res => {
        console.log(res, "规则监控");
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.tableData = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
      })
    },
    NumFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = Number(parseFloat(value).toFixed(2))
      return realVal
    },
    //点击查看
    FollowUpMonitor(item, index) {
      this.TaskID = item.Id
      this.isactive = index
      this.detailed = item
      if (this.detailed.SuccessCount == 0 && this.detailed.TotalCount == 0) {
        this.detailed["percentage"] = 0
      } else {
        this.detailed["percentage"] = this.NumFilter(this.detailed.SuccessCount / this.detailed.TotalCount) * 100
      }
      this.FollowUpMonitorlist(item.Id)
    },
    //任务包明细
    FollowUpMonitorlist(FollowUpTaskPackageId, Status) {
      const parameter = {
        FollowUpTaskPackageId: FollowUpTaskPackageId,
        Status: Status,
        Key: this.listQuery1.Key,
        page: this.listQuery1.page,
        rows: this.listQuery1.rows,
      }
      FollowTask.FollowUpMonitorData(parameter).then(res => {
        if (res.data.Status == 1) {
          console.log(res.data.Data.rows, "任务包明细")
          this.total1 = res.data.Data.total
          this.dataList = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    //项目
    async ProjectList() {
      derive.ProjectListData().then(res => {
        if (res.data.Status == 1) {
          this.list.push(...JSON.parse(JSON.stringify(res.data.Data)))
          this.ruleList.push(...res.data.Data)
          this.FollowUpTestPackagelist(this.ProjectId, '', '', '')
        } else {
          alert(res.data.Message)
        }
        console.log(res, '项目');
      })
    },
    //查询任务检测按钮
    FollowUpTestPackage() {
      this.FollowUpTestPackagelist(this.ProjectId.slice(-1), this.Name, this.time[0], this.time[1], this.CreatedBy)
    },
    FollowUpTestPackagelist(ProjectId, Name, time0, time1, CreatedBy) {
      const parameter = {
        ProjectId: ProjectId,
        Name: Name,
        BeginTime: time0,
        EndTime: time1,
        Key: CreatedBy,
        page: 1,
        rows: 10,
      }
      FollowTask.FollowUpTestPackageData(parameter).then(res => {
        console.log(res);
        if (res.data.Status == 1) {
          console.log(res.data.Data.rows, "任务监测")
          this.testing = res.data.Data.rows.map(item => {
            item["percentage"] = this.NumFilter(item.SuccessCount / item.TotalCount) * 100
            return item
          })
          if (this.testing.length != 0) {
            this.detailed = this.testing[0]
            if (this.detailed.SuccessCount == 0 && this.detailed.TotalCount == 0) {
              this.detailed["percentage"] = 0
            } else {
              this.detailed["percentage"] = this.NumFilter(this.detailed.SuccessCount / this.detailed.TotalCount) * 100
            }
            this.TaskID = this.testing[0].Id
            this.FollowUpMonitorlist(this.TaskID)
          } else {
            this.detailed = {}
            this.dataList = []
          }
        } else {
          alert(res.data.Message)
        }
      })
    },
    format(item) {
      console.log(item);
      return () => {
        return `${item.FinishCount}/${item.TotalCount}`;
      }
    },
  }

}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.active {
  border: 1px solid #3388ff !important;

}

.task_box2 {
  ::v-deep .el-input {
    width: 240px;
    height: 34px;
    margin-right: 20px;

    .el-input__inner {
      width: 240px;
      height: 34px;
      @include add-size($font_size_16);
    }
  }

  ::v-deep .el-button {
    @include common_button_primary;
    margin-left: 20px;
  }

  ::v-deep .el-range-editor.el-input__inner {
    height: 34px;
    padding: 0 10px;
  }
}

.task_box1 {
  box-sizing: border-box;
  padding: 20px 30px 20px 30px;
}

::v-deep .el-tabs__header {
  margin: 0px 35px 0px;
}

::v-deep .el-tabs__item {
  font-size: 18px;
}

.task_box {
  background: #F0F7FD;
  box-sizing: border-box;
  // height: 800px;
  // padding: 10px;
  .el-tabs {
    height: 100%;

    ::v-deep .el-tabs__content {
      height: calc(100% - 40px);

      .el-tab-pane {
        height: 100%;
      }
    }
  }
}

.task-page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .task-header {
    width: calc(100% - 60px);
    // height: 70px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 10px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;

    span {
      @include common_span
    }

    ::v-deep .el-input {
      width: 240px;
      height: 34px;
      margin-right: 20px;

      .el-input__inner {
        width: 240px;
        height: 34px;
        @include add-size($font_size_16);
      }
    }

    ::v-deep .el-button {
      @include common_button_primary;
      margin-left: 20px;
    }

    ::v-deep .el-range-editor.el-input__inner {
      height: 34px;
      padding: 0 10px;
    }
  }

  .task-content {
    display: flex;
    width: calc(100% - 60px);
    // height: calc(100% - 120px);
    height: calc(100% - 110px);
    margin: 0 auto;
    margin-top: 10px;

    .task-content-left {
      width: 310px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 10px;

      ul {
        height: 100%;
        padding: 20px 0 20px 15px;
        box-sizing: border-box;

        li {
          width: 280px;
          height: 160px;
          border: 1px solid #E9E9E9;
          margin-bottom: 10px;
          padding: 10px 11px 0 19px;
          box-sizing: border-box;

          ::v-deep .el-progress-bar {
            margin: 0;
            padding: 0;
            width: 118px;
            margin-top: 2px;
          }

          .task-bottom {
            margin-bottom: 6px;

            span {
              @include add-size5($font_size_14);
              font-weight: 400;
              //line-height: 24px;
              color: #585858;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .task-content-right {
      width: calc(100% - 320px);
      margin-left: 10px;
      height: 100%;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 10px;
      padding: 19px 30px 0 30px;
      box-sizing: border-box;

      .task-content-right-header {
        span {
          @include common_span
        }
      }

      .task-content-query {
        span {
          @include common_span
        }

        ::v-deep .el-input {
          width: 180px;
          height: 34px;

          .el-input__inner {
            width: 180px;
            height: 34px;
            @include add-size($font_size_16)
          }
        }

        ::v-deep .el-button {
          @include common_button_primary;
          margin-left: 20px;
        }

        ::v-deep .el-button.el-button-plan {
          @include common_button_plain;
        }
      }
    }

    .task-content-left-span {
      @include common_span;
      color: #333;
    }

    ::v-deep .el-tag {
      height: 24px;
      line-height: 24px;
      padding-left: 9px;
      padding-right: 9px;
    }
  }
}

::v-deep .el-drawer {
  opacity: 1;
  height: 100%;

  .el-drawer__header {
    padding: 0 20px 0 0;
    margin: 0;
  }

  .detailsDrawer-components {
    background: #F3F4F5;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  }

  .header-title {
    width: 1201px;
    height: 60px;
    background: #FFFFFF;
    opacity: 1;
    line-height: 60px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {

  .task-header {
    ::v-deep .el-input__inner {
      width: 140px !important;
    }

    ::v-deep .el-input {
      width: 140px !important;
    }

    ::v-deep .el-date-editor {

      width: 300px !important;
      margin-top: 10px;

    }
  }

  .task-content-query {

    ::v-deep .el-input__inner {
      width: 95px !important;
    }

    ::v-deep .el-input {
      width: 95px !important;
    }

    .el-select {
      margin-right: 5px !important;
    }

    span {
      margin-right: 5px !important;
    }

    .el-button {
      margin-left: 0 !important;
      margin-top: 10px;
      margin-right: 5px;
    }

  }

  .task_box2 {
    ::v-deep .el-input__inner {
      width: 120px !important;
    }

    ::v-deep .el-input {
      width: 120px !important;
    }
  }

}
</style>
