// 随访任务api
import axios from "./Interceptor.js"
let address =window.global_config.BASE_URL2
let FollowTask = {
    FollowUpTestPackageData: (info) => {//随访任务监测任务包接口
        return axios({
            method: "post", 
            url: `${address}FollowUp/Monitor/TaskPackage/GetPageList`,
            data:info
        })
    },
    FollowUpMonitorData: (info) => {//任务包明细
        return axios({
            method: "post", 
            url: `${address}FollowUp/Monitor/Task/GetPageList`,
            data:info
        })
    },
    RuleMonitoringData: (info) => {//规则监测列表
        return axios({
            method: "post", 
            url: `${address}FollowUp/Monitor/Rule/GetPageList`,
            data:info
        })
    },
    
}
export default FollowTask